import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-201f6120"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "padding max-height max-width" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"margin-top":"20px"} }
const _hoisted_4 = {
  key: 0,
  style: {"margin-top":"20px"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { style: {"margin-top":"20px"} }
const _hoisted_7 = { style: {"text-align":"center","margin-top":"25px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeficiencyFormatter = _resolveComponent("DeficiencyFormatter")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_FollowUpTitle = _resolveComponent("FollowUpTitle")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_EvaluationEmailLink = _resolveComponent("EvaluationEmailLink")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.visitDeficiencies.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_DeficiencyFormatter, { deficiencies: _ctx.visitDeficiencies }, null, 8, ["deficiencies"]),
          _createVNode(_component_v_textarea, {
            modelValue: _ctx.inputValues['compensation_plan'],
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValues['compensation_plan']) = $event)),
            "hide-details": true,
            outlined: "",
            placeholder: "Åtgärdsplan"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_FollowUpTitle, null, {
          default: _withCtx(() => [
            _createTextVNode(" Krävs Uppföljning? ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_radio_group, {
          modelValue: _ctx.inputValues['need_follow_up'],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValues['need_follow_up']) = $event)),
          style: {"margin-top":"15px !important","margin-bottom":"10px !important"},
          "hide-details": true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yesAndNoOptions, (option, value) => {
              return (_openBlock(), _createBlock(_component_v_radio, {
                key: `need_follow_up_${value}`,
                value: option.value,
                color: "info"
              }, {
                label: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(option.name), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createElementVNode("div", null, [
          _createVNode(_component_v_textarea, {
            modelValue: _ctx.inputValues['need_follow_up_answered_no_comment'],
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.inputValues['need_follow_up_answered_no_comment']) = $event)),
            "hide-details": true,
            outlined: "",
            placeholder: "Kommentar"
          }, null, 8, ["modelValue"])
        ])
      ]),
      (_ctx.addReasonFollowUp)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_FollowUpTitle, null, {
              default: _withCtx(() => [
                _createTextVNode(" Vill du lägga till anledning till Uppföljning? ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_radio_group, {
              modelValue: _ctx.inputValues['add_reason_for_follow_up'],
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inputValues['add_reason_for_follow_up']) = $event)),
              style: {"margin-top":"15px !important","margin-bottom":"10px !important"},
              "hide-details": true
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yesAndNoOptions, (option, value) => {
                  return (_openBlock(), _createBlock(_component_v_radio, {
                    key: `add_reason_for_follow_up_${value}`,
                    value: option.value,
                    color: "info"
                  }, {
                    label: _withCtx(() => [
                      _createElementVNode("span", null, _toDisplayString(option.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"]),
            (_ctx.reasonFollowUp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_v_textarea, {
                    modelValue: _ctx.inputValues['reason_for_follow_up'],
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputValues['reason_for_follow_up']) = $event)),
                    "hide-details": true,
                    outlined: "",
                    placeholder: "Anledning till uppföljning"
                  }, null, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_FollowUpTitle, null, {
        default: _withCtx(() => [
          _createTextVNode(" Vid frågor, vänligen kontakta SKKs Kansli på e-postadressen ")
        ]),
        _: 1
      }),
      _createElementVNode("h2", _hoisted_7, [
        _createVNode(_component_EvaluationEmailLink, { input: {
                        email: 'kennelkonsulent@skk.se',
                    } }, null, 8, ["input"])
      ])
    ]),
    _createVNode(_component_v_alert, {
      modelValue: _ctx.showWarning,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showWarning) = $event)),
      color: "warning",
      width: "460px",
      closable: "",
      icon: "mdi-alert"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.warningMessage), 1)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_v_btn, {
      style: {"margin-top":"50px"},
      block: "",
      color: "success",
      onClick: _ctx.saveFollowUp
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Spara ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}