import { useEvaluationsStore, useRegistrationsStore, } from '@/stores/store';
import { checkScopeRequirements, checkMandatoryInputsAndMarkDone, getCurrentJsonnetFile } from '@/helpers/InputHelpers';
import { getEvent, getRegistration } from '@/helpers/Getters';
const cleanChanges = async (event, registration, type, savedValues, changes) => {
    if (['bird_skf', 'hunting_swk_hunting'].includes(event.type)) {
        return changes;
    }
    const file = getCurrentJsonnetFile(event.id, registration.id);
    if (!file) {
        return changes;
    }
    let evals = null;
    if (file.type === 'working_test') {
        evals = file.evaluations;
    }
    else {
        evals = file[type];
    }
    const shownInputs = evals.filter((input) => checkScopeRequirements(input, savedValues, changes));
    const nonRemovableScopes = [];
    shownInputs.forEach((input) => {
        const nonRemovable = input.inputs.flatMap((block) => block.scope);
        nonRemovableScopes.push(...nonRemovable);
    });
    //if (this.event.type === 'kennelvisit') {
    const evaluationsStore = useEvaluationsStore();
    await evaluationsStore.cleanChanges(type, registration, nonRemovableScopes);
    //}
    return changes.filter((block) => nonRemovableScopes.includes(block.type));
};
export const saveToFirestore = async (eventId, regId, type, no_show, savedValues, changes) => {
    const event = getEvent(eventId);
    const registration = getRegistration(regId);
    const registrationsStore = useRegistrationsStore();
    if (event.type !== 'kennelvisit') {
        try {
            await registrationsStore.addResult(registration.id, type, [
                {
                    comment: '',
                    type: 'no_show',
                    value: no_show,
                    value_text: no_show ? 'Kom ej till start' : 'Kom till start',
                    scope: null,
                    dynamic: null,
                }
            ]);
        }
        catch (error) {
            console.error('Failed setting no show values for kennelvist with', error);
        }
    }
    if (no_show === 1) {
        try {
            await registrationsStore.MarkDone(registration.id, type);
        }
        catch (error) {
            console.error('Failed marking done when no show with', error);
        }
        return { valid: false, missing: [] };
    }
    let done = false;
    try {
        done = await registrationsStore.isEvaluationDone(registration.id, type);
    }
    catch (error) {
        console.error('Failed evaluation done check with', error);
    }
    if (done) {
        try {
            await registrationsStore.MarkIncomplete(registration.id, type);
        }
        catch (error) {
            console.error('Marking incomplete failed with', error);
        }
    }
    let valid = {};
    let cleanedChanges = [];
    try {
        valid = await checkMandatoryInputsAndMarkDone(eventId, regId, type, savedValues, changes);
        cleanedChanges = await cleanChanges(event, registration, type, savedValues, changes);
    }
    catch (error) {
        console.error('Input validation and cleaning failed with', error);
    }
    if (!cleanedChanges.length) {
        console.info('No changes to save');
        return valid;
    }
    try {
        await registrationsStore.addResult(regId, type, cleanedChanges);
    }
    catch (error) {
        console.error('Failed adding results with', error);
    }
    if (event.type === 'kennelvisit') {
        const markVisit = changes.filter((c) => c.type === 'visit_completed');
        if (markVisit.length) {
            try {
                await registrationsStore.markVisitComplete(regId, markVisit[0].value);
            }
            catch (error) {
                console.error('Failed setting kennelvist as complete with', error);
            }
        }
    }
    return valid;
};
