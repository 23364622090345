import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "layout-container-fullscreen layout-padding-top"
};
const _hoisted_2 = { class: "d-flex flex-column align-center" };
const _hoisted_3 = {
    key: 1,
    class: "layout-container-fullscreen layout-padding-top"
};
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { class: "page-title d-flex justify-center mb-4" };
const _hoisted_6 = { key: 0 };
const _hoisted_7 = { key: 1 };
const _hoisted_8 = { class: "mb-8" };
const _hoisted_9 = { class: "d-flex justify-end mt-n4 text-subtitle-2" };
const _hoisted_10 = {
    key: 0,
    class: "mr-auto"
};
const _hoisted_11 = { key: 0 };
const _hoisted_12 = { key: 1 };
const _hoisted_13 = { key: 0 };
const _hoisted_14 = {
    key: 1,
    class: "layout-container-fullscreen layout-padding-top"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_v_icon = _resolveComponent("v-icon");
    const _component_v_btn = _resolveComponent("v-btn");
    const _component_v_progress_circular = _resolveComponent("v-progress-circular");
    const _component_v_col = _resolveComponent("v-col");
    const _component_v_container = _resolveComponent("v-container");
    const _component_v_text_field = _resolveComponent("v-text-field");
    const _component_v_list_item_title = _resolveComponent("v-list-item-title");
    const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle");
    const _component_v_list_item = _resolveComponent("v-list-item");
    const _component_v_list = _resolveComponent("v-list");
    return (!_ctx.events.synced && !_ctx.kkInstance)
        ? (_openBlock(), _createBlock(_component_v_container, {
            key: 0,
            class: _normalizeClass({ 'green-bg': _ctx.kkInstance })
        }, {
            default: _withCtx(() => [
                _createVNode(_Transition, null, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                                (!_ctx.events.isSyncing)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                        _createVNode(_component_v_icon, {
                                            icon: "mdi-cloud-sync",
                                            size: "120",
                                            color: "var(--green-3)",
                                            class: "mb-8"
                                        }),
                                        _createElementVNode("div", _hoisted_2, [
                                            _createVNode(_component_v_btn, {
                                                variant: "flat",
                                                color: "info",
                                                class: "mb-2",
                                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.syncEvents()))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(" Synkronisera ")
                                                ]),
                                                _: 1
                                            }),
                                            _createVNode(_component_v_btn, {
                                                variant: "text",
                                                onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.events.synced = true))
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(" Avbryt ")
                                                ]),
                                                _: 1
                                            })
                                        ])
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                        _createVNode(_component_v_progress_circular, {
                                            indeterminate: "",
                                            color: "var(--green-3)",
                                            size: "150",
                                            width: "20"
                                        })
                                    ]))
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["class"]))
        : (_openBlock(), _createBlock(_Transition, { key: 1 }, {
            default: _withCtx(() => [
                _createVNode(_component_v_container, {
                    class: _normalizeClass({ 'green-bg': _ctx.kkInstance })
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "12" }, {
                            default: _withCtx(() => [
                                (!_ctx.events.isLoading)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                        _createElementVNode("div", _hoisted_5, [
                                            (_ctx.kkInstance)
                                                ? (_openBlock(), _createElementBlock("h1", _hoisted_6, " Välj dag "))
                                                : (_openBlock(), _createElementBlock("h1", _hoisted_7, " Välj event "))
                                        ]),
                                        _createElementVNode("div", _hoisted_8, [
                                            _createVNode(_component_v_text_field, {
                                                modelValue: _ctx.search,
                                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.search) = $event)),
                                                placeholder: "Sök",
                                                variant: "solo",
                                                "append-inner-icon": "mdi-magnify",
                                                class: "rounded-md"
                                            }, null, 8, ["modelValue"]),
                                            _createElementVNode("div", _hoisted_9, [
                                                (_ctx.search !== '')
                                                    ? (_openBlock(), _createElementBlock("strong", _hoisted_10, " Sökträffar: " + _toDisplayString(_ctx.filteredEvents.length), 1))
                                                    : _createCommentVNode("", true),
                                                _createElementVNode("strong", null, [
                                                    _createTextVNode(" Registrerade "),
                                                    (_ctx.kkInstance)
                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, " besök: "))
                                                        : (_openBlock(), _createElementBlock("span", _hoisted_12, "provtillfällen:")),
                                                    _createTextVNode(" " + _toDisplayString(_ctx.events.events.length), 1)
                                                ])
                                            ])
                                        ]),
                                        _createVNode(_component_v_list, {
                                            class: "pa-0",
                                            style: { "background": "none" }
                                        }, {
                                            default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredEvents, (event) => {
                                                    return _withDirectives((_openBlock(), _createBlock(_component_v_list_item, {
                                                        key: event.id,
                                                        elevation: "2",
                                                        class: "rounded-md mb-2 px-4 py-2 bg-white",
                                                        onClick: ($event) => (_ctx.toEvent(event))
                                                    }, {
                                                        append: _withCtx(() => [
                                                            _createVNode(_component_v_icon, { color: "info" }, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(" mdi-arrow-right ")
                                                                ]),
                                                                _: 1
                                                            })
                                                        ]),
                                                        default: _withCtx(() => [
                                                            _createVNode(_component_v_list_item_title, { class: "text-subtitle-1" }, {
                                                                default: _withCtx(() => [
                                                                    (!_ctx.kkInstance)
                                                                        ? (_openBlock(), _createElementBlock("strong", _hoisted_13, " #" + _toDisplayString(_ctx.getEventId(event)) + " - ", 1))
                                                                        : _createCommentVNode("", true),
                                                                    _createElementVNode("strong", null, _toDisplayString(event.title), 1)
                                                                ]),
                                                                _: 2
                                                            }, 1024),
                                                            (_ctx.classList(event))
                                                                ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                                                                    key: 0,
                                                                    class: "text-subtitle-2"
                                                                }, {
                                                                    default: _withCtx(() => [
                                                                        _createTextVNode(_toDisplayString(_ctx.classList(event)), 1)
                                                                    ]),
                                                                    _: 2
                                                                }, 1024))
                                                                : _createCommentVNode("", true),
                                                            _createVNode(_component_v_list_item_subtitle, { class: "text-subtitle-2" }, {
                                                                default: _withCtx(() => [
                                                                    _createTextVNode(_toDisplayString(_ctx.eventDate(event)), 1)
                                                                ]),
                                                                _: 2
                                                            }, 1024)
                                                        ]),
                                                        _: 2
                                                    }, 1032, ["onClick"])), [
                                                        [_vShow, event.visible]
                                                    ]);
                                                }), 128))
                                            ]),
                                            _: 1
                                        })
                                    ]))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                        _createVNode(_component_v_progress_circular, {
                                            indeterminate: "",
                                            color: "var(--green-3)",
                                            size: "150",
                                            width: "20"
                                        })
                                    ]))
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }, 8, ["class"])
            ]),
            _: 1
        }));
}
