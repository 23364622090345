<template>
    <div>
        <v-slider
            v-if="dynamic !== null && dynamicIndex !== null"
            v-model="dynamic[dynamicIndex][input.scope]"
            :messages="getSliderMessage"
            :min="kebEnabled ? input.min - 1 : input.min"
            :max="input.max"
            :step="input.step ? input.step : 1"
        >
            <template #append>
                <nobr>
                    {{ scoreNumber }}
                </nobr>
            </template>
        </v-slider>
        <v-slider
            v-else
            v-model="selected[input.scope]"
            :messages="getSliderMessage"
            :min="kebEnabled ? input.min - 1 : input.min"
            :max="input.max"
            :step="input.step ? input.step : 1"
        >
            <template #append>
                <nobr v-if="!input.values[selected[input.scope]]?.custom_box">
                    {{ scoreNumber }}
                </nobr>
                <nobr v-else>
                    {{ input.values[selected[input.scope]].custom_box }}
                </nobr>
            </template>
        </v-slider>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ERange',
    props: {
        dynamic: {
            type: Object,
            default: null
        },
        dynamicIndex: {
            type: Number,
            default: null
        },
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    emits: ['setScope'],
    computed: {
        isDynamic() {
            return this.dynamic !== null && this.dynamicIndex !== null;
        },

        scoreNumber() {
            const value = this.isDynamic
                ? this.dynamic[this.dynamicIndex][this.input.scope]
                : this.selected[this.input.scope];
            switch (value) {
                case -1:
                case this.input.min - 1:
                    return this.input.keb_display ?? 'KEB';
                case 0:
                    return this.input.noZero ? '-' : 0;
                default:
                    return value;
            }
        },

        kebEnabled() {
            return this.input.kebable;
        },

        getSliderMessage() {
            if (!this.input.values) {
                return '';
            }
            
            const inputValue = (this.dynamic !== null && this.dynamicIndex != null) ?
                this.dynamic[this.dynamicIndex][this.input.scope] :
                this.selected[this.input.scope];

            if (this.input.values && inputValue === undefined) {
                return '';
            }

            if (inputValue === -1) {
                if (this.input.keb_display === '-') {
                    return 'Ingen poängsättning';
                }
                return this.kebEnabled ? this.input.keb_display ?? 'KEB' : '';
            }

            const value = this.input.values.find((v: any) => v.value === inputValue);

            if (!value) {
                return this.kebEnabled ? this.input.keb_display ?? 'KEB' : '';
            }

            return value.name;
        },
    },
    created() {
        if (this.dynamic !== null && this.dynamicIndex !== null) {
            this.handleDynamicValueAssign();
            return;
        }

        this.handleNormalValueAssign();
    },
    methods: {
        handleDynamicValueAssign() {
            if (this.dynamic[this.dynamicIndex][this.input.scope]) {
                return;
            }

            if (!this.kebEnabled) {
                this.dynamic[this.dynamicIndex][this.input.scope] = this.input.min;
                return;
            }

            this.dynamic[this.dynamicIndex][this.input.scope] = -1;
        },

        handleNormalValueAssign() {
            if (this.selected[this.input.scope] === -1 && !this.kebEnabled) {
                this.$emit('setScope', this.input.scope, this.input.min);
            } else if (this.selected[this.input.scope] === -1) {
                this.$emit('setScope', this.input.scope, -1);
            }
        }
    },
})

</script>
