import skf from '@/assets/jsonnet/skf.jsonnet';
import fa from '@/assets/jsonnet/fa.jsonnet';
import chase from '@/assets/jsonnet/chase.jsonnet';
import taxchase from '@/assets/jsonnet/taxchase.jsonnet';
import adbchase from '@/assets/jsonnet/adbchase.jsonnet';
import bassetchase from '@/assets/jsonnet/bassetchase.jsonnet';
import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet';
import kennelvisit from '@/assets/jsonnet/kennelvisit.jsonnet';
import workingtest from '@/assets/jsonnet/workingtest.jsonnet';
import huntingretriever from '@/assets/jsonnet/ssrk.jsonnet';
import hunting_swk from '@/assets/jsonnet/hunting_swk.jsonnet';
import hunting_swk_hunting from '@/assets/jsonnet/hunting_swk_hunting.jsonnet';
import hunting_swk_forest from '@/assets/jsonnet/hunting_swk_forest.jsonnet';
import huntingspaniel from '@/assets/jsonnet/ssrkspan.jsonnet';
import hunting_mock from '@/assets/jsonnet/huntingmock.jsonnet';
import huntingfunction from '@/assets/jsonnet/ssrkfunction.jsonnet';
import hunting_tolling from '@/assets/jsonnet/huntingtolling.jsonnet';
import hunting_tolling_practical from '@/assets/jsonnet/huntingtolling_practical.jsonnet';
import hunting_tjtk_skott_vatten from '@/assets/jsonnet/hunting_tjtk_skott_vatten.jsonnet';
import hunting_tjtk_anlagsprov_skog from '@/assets/jsonnet/hunting_tjtk_anlagsprov_skog.jsonnet'
import hunting_tjtk_anlagsprov_falt from '@/assets/jsonnet/hunting_tjtk_anlagsprov_falt.jsonnet';
import hunting_tjtk_anlagsprov_vildsvin from '@/assets/jsonnet/hunting_tjtk_anlagsprov_vildsvin.jsonnet';

import {
    getEvent, getRegistration
} from '@/helpers/Getters';

import { 
    TypeEvaluation, 
    useRegistrationsStore,
} from '@/stores/store';

const checkRequirementsForMandatory = (block: any, savedValues: any, changes: any) => {
    if (!checkScopeRequirements(block, savedValues, changes)) {
        return false;
    }

    if (!block.requires) {
        return true;
    }

    const req = getRequiredValues(block, savedValues, changes);

    // @ts-ignore
    const reqFind = req?.find((r: any) => !r.requiredValue.includes(r.input.value));
    if (reqFind) {
        return false;
    }

    return true;
}

const getRequiredValues = (input: any, savedValues: any, changes: any) => {
    if (input.requires) {
        const split = input.requires.split(':');

        if (split.length > 2) {
            const requiredScope = [] as string[];
            const requiredValues = [] as string[];

            split.forEach((entry: any, idx: number) => {
                if ((idx % 2) === 0) {
                    requiredScope.push(entry);
                } else {
                    requiredValues.push(entry);
                }
            });

            const foundInput = requiredScope.map((reqScope: string, idx: number) => {
                let found = changes.find((c: any) => c.type === reqScope) as any;

                if (!found) {
                    if (!savedValues) {
                        return null;
                    }

                    found = savedValues.find((v: any) => v.type === reqScope) as any;
                    if (!found) {
                        return null;
                    }
                }

                return {
                    input: found,
                    requiredValue: requiredValues[idx].split('|')
                };
            }).filter((v) => v);

            return foundInput;
        }

        const requiredScope = split[0];
        const requiredValues = split[1].split('|');

        let found = changes.find((c: any) => c.type === requiredScope) as any;

        if (!found && savedValues) {
            found = savedValues.find((v: any) => v.type === requiredScope) as any;
        }

        if (found) {
            return [{
                input: found,
                requiredValue: requiredValues
            }];
        }

        return null;
    }
    return null;
}

export const checkScopeRequirements = (scope: any, savedValues: any, changes: any) => {
    if (!scope.requires) {
        return true;
    }
    const split = scope.requires.split(':');

    const checkIncludeGreaterThenOrLessThen = (reqValues: string[], input: any) => {
        const greaterThenPassed = [] as boolean[];
        reqValues.forEach((v, i) => {
            if (/>=?|=/.test(v)) {
                greaterThenPassed[i] = v.split('>')[1] <= (Array.isArray(input.value) ? input.value.length : input.value);
                return;
            }
            if (/<=?|=/.test(v)) {
                greaterThenPassed[i] = v.split('<')[1] >= (Array.isArray(input.value) ? input.value.length : input.value);
                return;
            }
            greaterThenPassed[i] = false;
        })
        return greaterThenPassed.every(Boolean);
    }

    const requiredScope = [] as string[];
    const requiredValues = [] as string[];

    split.forEach((entry: any, idx: number) => {
        if ((idx % 2) === 0) {
            requiredScope.push(entry);
        } else {
            requiredValues.push(entry);
        }
    });

    return requiredScope.every((reqScope: string, idx: number) => {
        const allChanges = [...savedValues || [], ...changes || []]
        const allChangesKeepLastOccurrence = allChanges.filter(
            (obj, index) =>
                allChanges.findLastIndex((item) => item.type === obj.type) === index && typeof obj === 'object'
        );

        return allChangesKeepLastOccurrence.some((c: any) => {
            if (c.type !== reqScope) {
                return false;
            }

            const reqValues = requiredValues[idx].split('|');
            const includesGreaterThenOrLessThen = reqValues.findIndex((v) => /[<>]=?|=/.test(v)) >= 0;

            if (includesGreaterThenOrLessThen) {
                return checkIncludeGreaterThenOrLessThen(reqValues, c);
            }

            return reqValues.includes(c.value.toString());
        })
    });
}

export const checkMandatoryInputsAndMarkDone = 
    async (
        eventId: string,
        regId: string,
        type: string,
        savedValues: any,
        changes: any
    ) => {
    let mandatoryInputs = [] as Array<TypeEvaluation>;
    let validForMark = true;

    const jsonnetObject = getCurrentJsonnetFile(eventId, regId) as any;
    if (!jsonnetObject) {
        return {valid: false, missing: null};
    }

    if (jsonnetObject.type === 'working_test') {
        type = 'evaluations';
    }

    mandatoryInputs = jsonnetObject[type]
        .flatMap((block: any) =>
            block.inputs
                .filter((input: any) => input.mandatory === true && checkRequirementsForMandatory(block, savedValues, changes))
        );

    const missingInputs = [] as any;

    const pswmValues = (mandatoryInputs.find((v: any) => v.type === 'pswm') as any)?.values;
    if (pswmValues) {
        mandatoryInputs.push(...pswmValues);
    }

    const setNotValid = (val: any) => {
        validForMark = false;
        missingInputs.push(val);
    }

    mandatoryInputs.forEach((val: any) => {
        const cFound = changes.find((c: any) => c.type === val.scope) as any;

        if (cFound && Array.isArray(cFound.value)) {
            if (cFound.value.length === 0) {
                setNotValid(val);
            }


            if (cFound.type === 'litter') {
                cFound.value.forEach((v: any) => {
                    if (
                        v.litter_born === '' ||
                        v.litter_breed.length === 0 ||
                        (v.litter_num_female === 0 && v.litter_num_male === 0)
                    ) {
                        setNotValid(val);
                    }
                });
            }
        }

        if (!cFound) {
            if (!savedValues) {
                setNotValid(val);
                return;
            }

            const vFound = savedValues.find((v: any) => v.type === val.scope) as any;

            if (vFound && Array.isArray(vFound.value)) {
                if (vFound.value.length === 0) {
                    setNotValid(val);
                }

                if (vFound.type === 'litter') {
                    vFound.value.forEach((v: any) => {
                        if (
                            v.litter_born === '' ||
                            v.litter_breed.length === 0 ||
                            (v.litter_num_female === 0 && v.litter_num_male === 0)
                        ) {
                            setNotValid(val);
                        }
                    });
                }
            }

            if (!vFound || (vFound && vFound.value === '')) {
                setNotValid(val);
            }
        }

        if (cFound && cFound.value === '') {
            setNotValid(val);
        }
    })

    if (!validForMark) {
        return {
            valid: false, 
            missing: missingInputs
        };
    }

    console.info(`${type}: Marked as Done`);
    await useRegistrationsStore().MarkDone(regId, type);
    return { valid: true, missing: null };
}

export const getCurrentJsonnetFile = (eventId: string, regId: string) => {
    const event = getEvent(eventId);
    const registration = getRegistration(regId);

    const library = {
        'kennelvisit': () => kennelvisit,
        'bird_skf': () => {
            if (registration.class === 'SKL endast Breton') {
                return fa.highstatus.skl;
            }
            switch (registration.breed) {
                case 'BRAQUE FRANCAIS, TYPE PYRÉNÉES':
                case 'BRETON':
                case 'EPAGNEUL BRETON':
                    return skf.breton
                case 'BRACCO ITALIANO':
                case 'SPINONE ITALIANO':
                case 'SPINONE':
                    if (registration.class.includes('EKL')) {
                        return skf.brac_spin_ekl
                    }
                    return skf.brac_spin
                case 'SLOVENSKÝ HRUBOSRSTY STAVAC (OHAR)':
                case 'GRIFFON D\'ARRET À POIL DUR/KORTHALS':
                case 'GRIFFON D\'ARRET A POIL DUR':
                case 'WEIMARANER, KORTHÅRIG':
                case 'WEIMARANER KURZHAARIG':
                case 'WEIMARANER, LÅNGHÅRIG':
                case 'WEIMARANER LANGHAARIG':
                    if (registration.class.includes('EKL') && event.categories.findIndex((categoryEntry) => categoryEntry.value.includes('Internationellt')) !== -1) {
                        return skf.grif_weim_srhp_ekl_int;
                    } if (registration.class.includes('EKL') && event.categories.findIndex((categoryEntry) => categoryEntry.value.includes('Nationellt')) !== -1) {
                        return skf.grif_weim_srhp_ekl_nat;
                    }
                    return skf.grif_weim_srhp;
                default:
                    if (registration.class.includes('EKL')) {
                        return skf.drent_gdh_stab_other_ekl;
                    }
                    return skf.drent_gdh_stab_other
            }
        },
        'bird_fa': () => {
            switch (registration.class) {
                case 'SKL endast Breton':
                case 'SKL':
                    return fa.standard.skl;
                case 'UKL 9-24 mån': 
                    return fa.standard.ukl;
                case 'Apport':
                    return fa.standard.apport;
                case 'ÖKL över 9 mån':
                default:
                    return fa.standard.okl;
            }
        },
        'fa_highstatus': () => {
            switch (registration.class) {
                case 'SKL Internationell':
                case 'SKL endast Breton':
                case 'SKL':
                    return fa.highstatus.skl;
                case 'UKL 9-24 mån':
                default:
                    return fa.highstatus.ukl;
            }
        },
        'hunting_retriever_a': () => {
            const test_type = event.categories.find((category) => category.type === 'Provslag')?.value;

            if (test_type === 'Nationellt') {
                switch (registration.class) {
                    case 'ÖKL':
                        return huntingretriever.type_a.okl_nat;
                    case 'EKL':
                        return huntingretriever.type_a.ekl_nat;
                    default:
                        return huntingretriever.type_a.okl_nat;
                }
            }
            return huntingretriever.type_a.ekl_int
        },
        'hunting_retriever_b': () => huntingretriever.type_b,
        'hunting_spaniel_vatten': () => huntingspaniel.water,
        'hunting_spaniel_nyborjare': () => huntingspaniel.beginner,
        'hunting_spaniel_falt': () => {
            switch (registration.class) {
                case 'Öppenklass Enkelsläpp':
                    return huntingspaniel.okl
                case 'Öppenklass Parsläpp':
                    return huntingspaniel.okl_pair
                case 'Segrarklass Enkelsläpp':
                    return huntingspaniel.skl
                case 'Segrarklass Parsläpp':
                    return huntingspaniel.skl_pair
                default:
                    return huntingspaniel.skl;
            }
        },
        'hunting_spaniel_wt': () => huntingspaniel.working,
        'bloodtracking': () => bloodtracking.evaluation,
        'working_test': () => workingtest,
        'chase': () => chase.evaluation,
        'chase_tax': () => taxchase.evaluation,
        'chase_basset': () => bassetchase.evaluation,
        'chase_adb': () => adbchase.evaluation,
        'hunting_swk': () => hunting_swk,
        'hunting_tjtk': () => hunting_swk,
        'hunting_swk_hunting': () => {
            switch (registration.class) {
                case 'Öppen klass':
                    return hunting_swk_hunting.okl
                default:
                    return hunting_swk_hunting.other
            }
        },
        'hunting_swk_forest': () => hunting_swk_forest,
        'hunting_mock_trial': () => hunting_mock,
        'hunting_tolling': () => hunting_tolling,
        'hunting_tolling_practical': () => hunting_tolling_practical,
        'hunting_retriever_function': () => huntingfunction,
        'hunting_tjtk_skott_vatten': () => hunting_tjtk_skott_vatten,
        'hunting_tjtk_anlagsprov_skog': () => hunting_tjtk_anlagsprov_skog,
        'hunting_tjtk_anlagsprov_falt': () => hunting_tjtk_anlagsprov_falt,
        'hunting_tjtk_anlagsprov_vildsvin': () => hunting_tjtk_anlagsprov_vildsvin,
    } as { [key: string]: () => { [key: string]: Array<Object> } }
    return library[event.type]();
}
