import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, Suspense as _Suspense, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"position":"sticky","top":"0","z-index":"1000"}
}
const _hoisted_2 = { class: "content" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "layout-container-fullscreen layout-padding-top" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "info-text-container"
}
const _hoisted_8 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_registration_info = _resolveComponent("registration-info")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_PreviewSkkResults = _resolveComponent("PreviewSkkResults")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, {
    class: _normalizeClass([{'green-bg': _ctx.kkInstance}, "relative"])
  }, {
    default: _withCtx(() => [
      (_ctx.kkCheckMap[_ctx.user.getEndpoint])
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_v_alert, {
              modelValue: _ctx.showErrorAlert,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showErrorAlert) = $event)),
              type: "error",
              position: "sticky",
              class: "full-width"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.errorAlertMessage), 1)
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_alert, {
              modelValue: _ctx.showSentAlert,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showSentAlert) = $event)),
              type: _ctx.isOnline ? 'success' : 'error',
              position: "sticky",
              class: "full-width"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.sentAlertMessage), 1)
              ]),
              _: 1
            }, 8, ["modelValue", "type"]),
            (_ctx.isOnline && _ctx.visitDoneMarked)
              ? (_openBlock(), _createBlock(_component_v_alert, {
                  key: 0,
                  modelValue: _ctx.showUploadAlert,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showUploadAlert) = $event)),
                  type: "warning",
                  position: "sticky",
                  class: "full-width upload-alert"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createTextVNode(" Ta ett kort på blanketten och ladda upp till protokollet på "),
                      _createElementVNode("a", {
                        style: {"color":"white"},
                        href: _ctx.kennelLink
                      }, "www.skkkonsulent.se", 8, _hoisted_3)
                    ]),
                    _createVNode(_component_v_btn, {
                      color: "white",
                      density: "compact",
                      variant: "plain",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showUploadAlert = false))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createBlock(_component_v_alert, {
            key: 1,
            modelValue: _ctx.showSentAlert,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showSentAlert) = $event)),
            type: "success",
            position: "sticky",
            class: "full-width"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Resultatet är skickat till SKK Start ")
            ]),
            _: 1
          }, 8, ["modelValue"])),
      (_openBlock(), _createBlock(_resolveDynamicComponent('dialog-' + _ctx.event.type), {
        registration: _ctx.registration,
        "show-control": _ctx.openControl,
        onCancleControl: _cache[5] || (_cache[5] = ($event: any) => (_ctx.openControl = false)),
        onControlOk: _cache[6] || (_cache[6] = ($event: any) => (_ctx.controlPassed = 1)),
        onControlBad: _ctx.handleBadControl,
        onNoShow: _ctx.handleNoShow
      }, null, 40, ["registration", "show-control", "onControlBad", "onNoShow"])),
      (_openBlock(), _createBlock(_Suspense, {
        onResolve: _cache[9] || (_cache[9] = ($event: any) => (_ctx.getEvaluations()))
      }, {
        fallback: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_v_progress_circular, {
              indeterminate: "",
              color: "var(--green-3)",
              size: "150",
              width: "20",
              class: "mb-8"
            })
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_registration_info, {
            currentRegistration: _ctx.registration,
            "onUpdate:currentRegistration": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.registration) = $event)),
            currentEvent: _ctx.event,
            "onUpdate:currentEvent": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.event) = $event))
          }, null, 8, ["currentRegistration", "currentEvent"])
        ]),
        _: 1
      })),
      _createVNode(_component_v_col, { class: "action-col" }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent('action-' + _ctx.event.type), {
            "curr-reg": _ctx.registration,
            "curr-event": _ctx.event,
            "control-passed": _ctx.controlPassed,
            "is-no-show": _ctx.isNoShow,
            onClickBed: _ctx.goToEvaluation,
            onClickControl: _cache[10] || (_cache[10] = ($event: any) => (_ctx.openControl = true)),
            onClickPaid: _ctx.setPaidStatus
          }, null, 40, ["curr-reg", "curr-event", "control-passed", "is-no-show", "onClickBed", "onClickPaid"])),
          (_ctx.user.getEndpoint.includes('skk'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_v_divider, { class: "mt-2 mb-10" }),
                _createVNode(_component_v_btn, {
                  color: "info",
                  block: "",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showSkkPreview = true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Granska resultat ")
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_dialog, {
                  modelValue: _ctx.showSkkPreview,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showSkkPreview) = $event)),
                  contained: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PreviewSkkResults, {
                      "curr-event": _ctx.event,
                      "curr-reg": _ctx.registration,
                      "skk-ajax-lock": _ctx.ajaxLock,
                      onPreviewBad: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showSkkPreview = false)),
                      onPreviewGood: _cache[13] || (_cache[13] = ($event: any) => (_ctx.sendToSKK()))
                    }, null, 8, ["curr-event", "curr-reg", "skk-ajax-lock"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                (_ctx.resultLink)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      color: "info",
                      block: "",
                      href: _ctx.resultLink,
                      target: "_blank"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Visa Resultat i SKK Start ")
                      ]),
                      _: 1
                    }, 8, ["href"]))
                  : _createCommentVNode("", true)
              ]))
            : (_ctx.kkCheckMap[_ctx.user.getEndpoint])
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_ctx.evaluationsLoaded)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createTextVNode(" - "),
                        _createElementVNode("p", {
                          class: "info-text",
                          textContent: _toDisplayString(_ctx.saveStatusText)
                        }, null, 8, _hoisted_8),
                        _createTextVNode(" - ")
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.kennelLink && _ctx.isOnline)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 1,
                        color: "info",
                        block: "",
                        href: _ctx.kennelLink,
                        target: "_blank",
                        class: "web-link-btn"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Visa protokollet på SKK Konsulent Web ")
                        ]),
                        _: 1
                      }, 8, ["href"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}